import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { documentColumns, notificationColumns, vendorColumns } from './Columns';
import ModalAddVendor from '../Modal/ModalAddVendor';
import ModalAddPerson from '../Modal/ModalAddPerson';
import { Link, useHistory, useParams } from '../../../util/router';
import {
  deleteInventoryDocuments,
  deleteInventoryEvents,
  setInventoryDocuments,
  setInventoryEvents,
  useGetPersonnel,
  useInventoryDocuments,
  useInventoryEquipments,
  useInventoryEventDetails,
  useInventoryEvents,
  useInventoryNotificationGroups,
  useInventoryNotifications,
  useInventorySettings,
  useInventoryVendors
} from '../../../util/db';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import moment from 'moment';
import { alphaNumericSorter, formatCurrency, getRecurringDates } from '../../../util/util';
import ModalFormEvent from '../Modal/ModalFormEvent';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { uploadFileAsync } from '../../../util/storage';

const EventDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: personnel = [] } = useGetPersonnel();
  const { data: rawEventDetails = [] } = useInventoryEventDetails(id);
  const { data: instruments = [] } = useInventoryEquipments();
  const { data: vendors = [] } = useInventoryVendors();
  const { data: documents = [] } = useInventoryDocuments();
  const { data: recurringEvents = [] } = useInventoryEvents({ parentEventId: id });
  const { data: inventorySettingsEventStatus = [] } = useInventorySettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: inventorySettingsDocumentType = [] } = useInventorySettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: inventorySettingsEventType = [] } = useInventorySettings(SETTING_TYPE.EVENT_TYPE);
  const { data: notifications = [] } = useInventoryNotifications({ eventId: id });
  const { data: groups = [] } = useInventoryNotificationGroups();

  const [isOpen, setOpen] = useState(false);
  const [isOpenAddDocument, setOpenAddDocument] = useState(false);
  const [isOpenAddVendor, setOpenAddVendor] = useState(false);
  const [isOpenAddPerson, setOpenAddPerson] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [editingDocument, setEditingDocument] = useState(null);

  useEffect(() => {
    if (rawEventDetails && rawEventDetails.length) {
      setEventDetails(rawEventDetails[0]);
    }
  }, [rawEventDetails]);

  const handleSave = async (data) => {
    createRecurringEvent(await setInventoryEvents({ ...eventDetails, ...data }));
    setOpen(false);
  };

  const handleDelete = async () => {
    if (confirm('Are you sure to delete this event?')) {
      await deleteInventoryEvents(eventDetails);
      history.push('/inventory/events');
    }
  };

  const handleMarkAsComplete = async () => {
    if (confirm('Are you sure to mark as complete this event?')) {
      const completeStatus = inventorySettingsEventStatus.find(
        (status) => status.name === 'Complete'
      );
      if (completeStatus) {
        createRecurringEvent(
          await setInventoryEvents({ ...eventDetails, status: completeStatus.id })
        );
      }
    }
  };
  const handleMarkAsInComplete = async () => {
    if (confirm('Are you sure to mark as incomplete this event?')) {
      await setInventoryEvents({ ...eventDetails, status: '' });
      if (recurringEvents.length > 0) {
        await deleteInventoryEvents(recurringEvents[0]);
      }
    }
  };

  const createRecurringEvent = async ({ id, eventNumber, status, ...restEvent }) => {
    if (restEvent.recurringEvent === 'Yes') {
      const eventStatus = inventorySettingsEventStatus.find((item) => item.id === status);
      if (
        eventStatus.name === 'Complete' &&
        moment().add(restEvent.recurringIn, 'days').startOf('day') <=
          moment(restEvent?.recurringUntil.seconds * 1000).startOf('day')
      ) {
        await setInventoryEvents({
          ...restEvent,
          parentEventId: id,
          dateDue: moment().add(restEvent.recurringIn, 'days').toDate()
        });
      }
    }
  };

  const deleteRecurringEvent = async ({ id, eventNumber, status, ...restEvent }) => {
    if (restEvent.recurringEvent === 'Yes') {
      const eventStatus = inventorySettingsEventStatus.find((item) => item.id === status);
      if (eventStatus.name === 'Complete') {
      }
    }
  };

  const handleAddVendors = async (data) => {
    const selectedVendors = Object.keys(data).filter((vendorId) => data[vendorId]);
    await setInventoryEvents({
      ...eventDetails,
      vendors: [...(eventDetails.vendors || []), ...selectedVendors]
    });
    setOpenAddVendor(false);
  };

  const handleDeleteVendor = async (data) => {
    const remainingVendors = eventDetails.vendors.filter((vendor) => vendor !== data.id);
    await setInventoryEvents({
      ...eventDetails,
      vendors: remainingVendors
    });
  };

  const handleDeleteDocument = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      if (data.attachment && data.attachment.fullPath) {
        await deleteFileAsync(data.attachment.fullPath);
      }
      await deleteInventoryDocuments(data);
    }
  };

  const handleAddPerson = async (data) => {
    const selectedGroups = Object.keys(data).filter((groupId) => data[groupId]);
    const selectedGroupPersons = groups
      .filter((group) => selectedGroups.includes(group.id))
      .map((group) => group.users);
    const selectedPersons = Array.from(new Set(selectedGroupPersons.flatMap((persons) => persons)));
    await setInventoryEvents({
      ...eventDetails,
      persons: [...Array.from(new Set([...(eventDetails?.persons || []), ...selectedPersons]))]
    });
    setOpenAddPerson(false);
  };

  const handleDeletePerson = async (data) => {
    const remainingPersons = eventDetails.persons.filter((person) => person !== data.id);
    await setInventoryEvents({
      ...eventDetails,
      persons: remainingPersons
    });
  };

  const handleCloneEvent = async () => {
    const { id, eventNumber, ...payload } = eventDetails;
    const data = await setInventoryEvents({ ...payload, eventNumber: `Clone from ${eventNumber}` });
    history.push(`/inventory/events/${data.id}`);
  };

  const handleAddDocuments = async (data) => {
    const { attachment, ...document } = {
      ...data,
      eventId: eventDetails.id,
      instrumentId: instrument.id
    };

    await setInventoryDocuments(document);

    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `inventory-management/${instrument.id}/${document.id}/${attachment[0].name}`,
        document
      );
    } else {
      document.attachment = attachment;
    }

    await setInventoryDocuments(document);

    setOpenAddDocument(false);
  };

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpenAddDocument(true);
  };

  const status = inventorySettingsEventStatus.find((item) => item.id === eventDetails?.status);
  const eventType = inventorySettingsEventType.find((item) => item.id === eventDetails?.eventType);
  const instrument = instruments.find((item) => item.id === eventDetails?.instrumentName);
  const responsibleParty = personnel.find((item) => item.id === eventDetails?.responsibleParty);

  return (
    <div className="event-details">
      <div className="event-header">
        <h2>Event Details</h2>
        <div className="actions">
          {status?.name !== 'Complete' ? (
            <Button variant="contained" onClick={handleMarkAsComplete}>
              Mark as complete
            </Button>
          ) : (
            <Button variant="contained" onClick={handleMarkAsInComplete}>
              Mark as Incomplete
            </Button>
          )}
          <Button variant="contained" onClick={() => setOpen(true)}>
            Edit
          </Button>
          <ModalFormEvent
            show={isOpen}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
            data={eventDetails}
            personnel={personnel}
            inventorySettingsEventStatus={inventorySettingsEventStatus}
            inventorySettingsEventType={inventorySettingsEventType}
            instruments={instruments}
          />
          <Button variant="contained" onClick={handleCloneEvent}>
            Clone
          </Button>
          <Button variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
      <div className="event-information">
        <h3>Event Information</h3>
        <table>
          <tbody>
            <tr>
              <td>Event Number</td>
              <td>{eventDetails?.eventNumber}</td>
              <td>Date Due</td>
              <td>
                {eventDetails?.dateDue
                  ? moment(eventDetails?.dateDue.seconds * 1000).format('MM/DD/YYYY')
                  : ''}
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{eventDetails?.description}</td>
              <td>Status</td>
              <td>{status?.name}</td>
            </tr>
            <tr>
              <td>Location</td>
              <td>{instrument?.location}</td>
              <td>Event Type</td>
              <td>{eventType?.name}</td>
            </tr>
            <tr>
              <td>Instrument Name</td>
              <td>
                <Link to={`/inventory/instruments/${instrument?.id}`}>{instrument?.name}</Link>
              </td>
              <td>Recurring Event</td>
              <td>{eventDetails?.recurringEvent?.substr(0, 1)}</td>
            </tr>
            <tr>
              <td>Responsible Party (SPA)</td>
              <td>{responsibleParty?.name}</td>
              <td>Recurring In (days)</td>
              <td>{eventDetails?.recurringEvent === 'Yes' ? eventDetails?.recurringIn : ''}</td>
            </tr>
            <tr>
              <td>Cost</td>
              <td>{formatCurrency(eventDetails?.cost)}</td>
              <td>Recurring Until</td>
              <td>
                {eventDetails?.recurringUntil
                  ? moment(eventDetails?.recurringUntil.seconds * 1000).format('MM/DD/YYYY')
                  : ''}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Notification Lead Time (days)</td>
              <td>
                {!isNaN(eventDetails?.notificationLeadTime)
                  ? eventDetails?.notificationLeadTime
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Notes</h3>
        <div>
          <Form.Group className="mb-3">
            <Form.Control as="textarea" rows={3} value={eventDetails?.notes} readOnly={true} />
          </Form.Group>
        </div>
        <h3>Documents</h3>
        <div className="table-box">
          <CustomTable
            data={documents.filter(
              (document) =>
                document.eventId === eventDetails?.id ||
                (document.instrumentId === instrument?.id && !document.eventId)
            )}
            header={documentColumns(showFormDocument, handleDeleteDocument)}
            deleteRowData={handleDeleteDocument}
            viewRowData={(data) => showFormDocument(data)}
            numberOfRows={10}
            action={['none']}
            sort={{ sorting: { sortModel: [{ field: 'documentId', sort: 'asc' }] } }}
          />
          <div className="button-below">
            <Button variant="contained" onClick={() => showFormDocument(null)}>
              Add document
            </Button>
            <ModalFormDocument
              show={isOpenAddDocument}
              handleClose={() => setOpenAddDocument(false)}
              handleSave={handleAddDocuments}
              data={editingDocument}
              disabled={editingDocument && !editingDocument?.eventId}
              inventorySettingsDocumentType={inventorySettingsDocumentType}
            />
          </div>
        </div>

        <h3>Vendors</h3>
        <div className="table-box">
          <CustomTable
            data={vendors.filter((vendor) => (eventDetails?.vendors || []).includes(vendor.id))}
            header={vendorColumns}
            deleteRowData={handleDeleteVendor}
            numberOfRows={10}
            action={['delete']}
            sort={{ sorting: { sortModel: [{ field: 'companyName', sort: 'asc' }] } }}
          />
          <div className="button-below">
            <Button variant="contained" onClick={() => setOpenAddVendor(true)}>
              Add vendor
            </Button>
            <ModalAddVendor
              show={isOpenAddVendor}
              handleClose={() => setOpenAddVendor(false)}
              handleSave={handleAddVendors}
              vendors={vendors
                .filter((vendor) => !(eventDetails?.vendors || []).includes(vendor.id))
                .map((vendor) => ({ ...vendor, name: vendor.companyName }))
                .sort(alphaNumericSorter)}
            />
          </div>
        </div>

        <h3>Notifications</h3>
        <div className="table-box">
          <CustomTable
            data={(eventDetails?.persons || []).map((personId) => {
              const ps = personnel.find((person) => person?.id === personId);
              return ps;
            })}
            header={notificationColumns}
            deleteRowData={handleDeletePerson}
            numberOfRows={10}
            action={['delete']}
            sort={{ sorting: { sortModel: [{ field: 'contact', sort: 'asc' }] } }}
          />
          <div className="button-below">
            <Button variant="contained" onClick={() => setOpenAddPerson(true)}>
              Add person
            </Button>
            <ModalAddPerson
              show={isOpenAddPerson}
              handleClose={() => setOpenAddPerson(false)}
              handleSave={handleAddPerson}
              groups={groups}
            />
          </div>
        </div>
        <div className="button-below text-center">
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
