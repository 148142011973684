import { Button } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { recurringEventOptions } from '../TabEvents/Columns';
import { renderMUILoadingButton } from '../../../util/util';

const ModalFormEvent = ({
  show,
  handleClose,
  handleSave,
  data,
  personnel = [],
  inventorySettingsEventStatus,
  inventorySettingsEventType,
  instruments,
  instrumentName = ''
}) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { value }
  } = useForm({
    defaultValues: {
      id: data?.id || '',
      eventNumber: data?.eventNumber || '',
      location: data?.location || '',
      instrumentName: data?.instrumentName || instrumentName || '',
      dateDue: data?.dateDue ? moment(data?.dateDue.seconds * 1000).toDate() : new Date(),
      status: data?.status || '',
      recurringEvent: data?.recurringEvent || 'No',
      recurringIn: data?.recurringIn || '',
      recurringUntil: data?.recurringUntil
        ? moment(data?.recurringUntil.seconds * 1000).toDate()
        : new Date(),
      responsibleParty: data?.responsibleParty || '',
      cost: data?.cost || '',
      notificationLeadTime: data?.notificationLeadTime || '',
      notes: data?.notes || '',
      description: data?.description || '',
      eventType: data?.eventType || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      eventNumber: data?.eventNumber || '',
      location: data?.location || '',
      instrumentName: data?.instrumentName || instrumentName || '',
      dateDue: data?.dateDue ? moment(data?.dateDue.seconds * 1000).toDate() : new Date(),
      status: data?.status || '',
      recurringEvent: data?.recurringEvent || 'No',
      recurringIn: data?.recurringIn || '',
      recurringUntil: data?.recurringUntil
        ? moment(data?.recurringUntil.seconds * 1000).toDate()
        : new Date(),
      responsibleParty: data?.responsibleParty || '',
      cost: data?.cost || '',
      notificationLeadTime: data?.notificationLeadTime || '',
      notes: data?.notes || '',
      description: data?.description || '',
      eventType: data?.eventType || ''
    });
  }, [show]);

  const formState = watch();

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };
  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Event' : 'Add Event'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Instrument Name</Form.Label>
                <Form.Select
                  name="instrumentName"
                  {...register('instrumentName', { required: true })}
                >
                  <option value="">-- Select --</option>
                  {instruments.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Event Type</Form.Label>
                <Form.Select name="eventType" {...register('eventType', { required: true })}>
                  <option value="">-- Select --</option>
                  {inventorySettingsEventType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  {...register('description', { required: false })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Responsible Party</Form.Label>
                <Form.Select
                  name="responsibleParty"
                  {...register('responsibleParty', { required: true })}
                >
                  <option value="">-- Select --</option>
                  {personnel.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Status</Form.Label>
                <Form.Select name="status" {...register('status', { required: true })}>
                  <option value="">-- Select --</option>
                  {inventorySettingsEventStatus.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Date Due</Form.Label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="dateDue"
                  render={({ field }) => <DatePicker {...field} selected={field.value} />}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Cost</Form.Label>
                <Form.Control
                  name="cost"
                  type="number"
                  {...register('cost', { valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Notification Lead Time (days)</Form.Label>
                <Form.Control
                  name="notificationLeadTime"
                  type="number"
                  {...register('notificationLeadTime', { valueAsNumber: true })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Recurring Event</Form.Label>
                <Form.Select
                  name="recurringEvent"
                  {...register('recurringEvent', {
                    required: true
                  })}
                >
                  {recurringEventOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {formState.recurringEvent === 'Yes' ? (
                <>
                  <Form.Group as={Col}>
                    <Form.Label>Recurring In (days)</Form.Label>
                    <Form.Control
                      name="recurringIn"
                      type="number"
                      {...register('recurringIn', {
                        required: true,
                        valueAsNumber: true
                      })}
                    />
                  </Form.Group>
                </>
              ) : (
                <Form.Group as={Col}></Form.Group>
              )}
            </Row>
            {formState.recurringEvent === 'Yes' ? (
              <Row style={{ marginTop: 10 }}>
                <Form.Group as={Col}>
                  <Form.Label>Recurring Until</Form.Label>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="recurringUntil"
                    render={({ field }) => <DatePicker {...field} selected={field.value} />}
                  />
                </Form.Group>
              </Row>
            ) : (
              <></>
            )}

            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  {...register('notes', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormEvent;
